/**
 * ATTENTION: Please use naming formula: {trial_pro | null}_{surface_area}_{noun}_{verb_past_tense}
 */

export enum TrackingEvents {
  ADDITIONAL_TRIAL_APPLIES_CHECKBOX_CHECKED = 'Additional Trial Applies Checkbox Checked',
  ADDITIONAL_TRIAL_APPLIES_CHECKBOX_UNCHECKED = 'Additional Trial Applies Checkbox UnChecked',
  ADDITIONAL_TRIAL_APPLIES_SUBMIT_BUTTON_CLICKED = 'Additional Trial Applies Submit Button Clicked',
  ARTICLE_CARD_CLICKED = 'Article Card Clicked',
  APPLY_NOW_CLICKED = 'Apply Now Clicked',
  APPLY_PACKAGE_ACCORDION_CLOSED = 'Apply Package Accordion Closed',
  APPLY_PACKAGE_ACCORDION_OPENED = 'Apply Package Accordion Opened',
  APPLY_PACKAGE_AVAILABILITY_SEEN = 'Apply Package Availability Seen',
  APPLY_PACKAGE_AVAILABILITY_CLICKED = 'Apply Package Availability Clicked',
  APPLY_PACKAGE_AVAILABILITY_SUBMIT_CLICKED = 'Apply Package Availability Submit Clicked',
  APPLY_PACKAGE_EDUCATION_SECTION_OPENED = 'Apply Package Education Section Opened',
  APPLY_PACKAGE_CALL_TRIAL_BUTTON_CLICKED = 'Apply Package Call Trial Button Clicked',
  APPLY_PACKAGE_MESSAGE_TRIAL_BUTTON_CLICKED = 'Apply Package Message Trial Button Clicked',
  APPLY_PACKAGE_CONSENT_FOR_EMR_CLOSED = 'Apply Package Consent For EMR Closed',
  APPLY_PACKAGE_CONSENT_FOR_EMR_COMPLETED = 'Apply Package Consent For EMR Completed',
  APPLY_PACKAGE_CONSENT_FOR_EMR_DECLINED = 'Apply Package Consent For EMR Declined',
  APPLY_PACKAGE_CONSENT_FOR_EMR_LOADED = 'Apply Package Consent For EMR Loaded',
  APPLY_PACKAGE_INSURANCE_STEP_SAVE_CLICKED = 'Apply Package Insurance Step Save Clicked',
  APPLY_PACKAGE_MEDICAL_RECORD_FILE_DELETED = 'Apply Package Medical Record File Deleted',
  APPLY_PACKAGE_MEDICAL_RECORD_FILE_UPLOADED = 'Apply Package Medical Record File Uploaded',
  APPLY_PACKAGE_PERSONAL_DETAILS_STEP_SAVE_CLICKED = 'Apply Package Personal Details Save Clicked',
  APPLY_PACKAGE_SUPPORTING_CONTACT_STEP_SAVE_CLICKED = 'Apply Package Supporting Contact Save Clicked',
  CALENDLY_APPOINTMENT_SCHEDULED = 'Calendly Appointment Scheduled', // Valueable Event - FB: Schedule
  CALENDLY_BUTTON_CLICKED = 'Calendly Button Clicked',
  CALENDLY_DATE_SELECTED = 'Calendly Date Selected',
  CLAIM_PROFILE_CLICKED = 'Claim Profile Clicked',
  CLAIM_SITE_CLICKED = 'Claim Site Clicked',
  CRC_COPILOT_ALL_STEPS_PREVIEW_CLOSED = 'CRC Co-Pilot All Step Preview Closed',
  CRC_COPILOT_ALL_STEPS_PREVIEW_OPENED = 'CRC Co-Pilot All Step Preview Opened',
  CRC_COPILOT_BOOK_NOW = 'CRC Co-Pilot Book Now',
  CRC_COPILOT_DID_ATTEND_SCREENING = 'CRC Co-Pilot Did Attend Screening',
  CRC_COPILOT_DID_CONNECT = 'CRC Co-Pilot Did Connect',
  CRC_COPILOT_DID_NOT_ATTEND_SCREENING = 'CRC Co-Pilot Did Not Attend Screening',
  CRC_COPILOT_DID_NOT_CONNECT = 'CRC Co-Pilot Did Not Connect',
  CRC_COPILOT_DISPATCHING_EVENT = 'CRC Co-Pilot Dispatching Event',
  CRC_COPILOT_FAILED_SCREENING = 'CRC Co-Pilot Failed Screening',
  CRC_COPILOT_GO_TO_PREVIOUS_STEP = 'CRC Co-Pilot Go To Previous Step',
  CRC_COPILOT_IS_ELIGIBLE_FOR_SCREENING = 'CRC Co-Pilot Is Eligible For Screening',
  CRC_COPILOT_JUMP_TO_STATE = 'CRC Co-Pilot Jump To State',
  CRC_COPILOT_LISTEN_TO_PRESCREENER_CLOSED = 'CRC Co-Pilot PreScreener Closed',
  CRC_COPILOT_LISTEN_TO_PRESCREENER_OPENED = 'CRC Co-Pilot PreScreener Opened',
  CRC_COPILOT_MARK_AS_CALLED = 'CRC Co-Pilot Mark As Called',
  CRC_COPILOT_NEXT_PATIENT_CLICKED = 'CRC Co-Pilot Next Patient Clicked',
  CRC_COPILOT_NOT_ELIGIBLE_FOR_SCREENING = 'CRC Co-Pilot Not Eligible For Screening',
  CRC_COPILOT_OPENED_INBOX = 'CRC Co-Pilot Opened Inbox',
  CRC_COPILOT_PASSED_SCREENING = 'CRC Co-Pilot Passed Screening',
  CRC_COPILOT_RANDOMIZED = 'CRC Co-Pilot Randomized',
  CRC_COPILOT_SCHEDULE_REMINDER = 'CRC Co-Pilot Schedule Reminder',
  CRC_COPILOT_SKIP = 'CRC Co-Pilot Skip',
  CRC_COPILOT_TOUR_DISMISSED = 'CRC Co-Pilot Tour Dismissed',
  DELETE_ACCOUNT_BUTTON_CLICKED = 'Delete Account Button Clicked',
  DIRECTORY_CARD_CLICKED = 'Directory Card Clicked',
  DIRECTORY_CLEAR_FILTERS_CLICKED = 'Directory Clear Filters Clicked',
  DIRECTORY_FILTER_MODIFIED = 'Directory Filter Modified',
  DIRECTORY_SEARCH_HERO_BUTTON_CLICKED = 'Directory Search Hero Button Clicked', // Valueable Event - FB: Search
  DIRECTORY_SEARCH_HISTORY_MODAL_VIEWED = 'Directory Search History Modal Viewed',
  DIRECTORY_SEARCH_HISTORY_APPLIED = 'Directory Search History Applied',
  DIRECTORY_SEARCH_INTERSTITIAL_BUTTON_CLICKED = 'Directory Search Interstitial Button Clicked',
  DIRECTORY_SEARCH_POPULAR_FILTERS_BUTTON_CLICKED = 'Directory Search Popular Filters Button Clicked',
  DIRECTORY_SEARCH_SEE_ALL_BUTTON_CLICKED = 'Directory Search See All Button Clicked',
  DIRECTORY_FILTER_PILL_CLICKED = 'Directory Filter Pill Clicked',
  DIRECTORY_SEARCH_BOX_MODIFIED = 'Directory Search Box Modified',
  DOCTOR_CARD_CLICKED = 'Doctor Card Clicked',
  DOCTOR_FAQ_ACCORDION_CLICKED = 'Doctor FAQ Accordion Clicked',
  DOCTOR_TOPIC_FAQ_ACCORDION_CLICKED = 'Doctor Topic FAQ Accordion Clicked',
  ELIGIBILITY_CHECKBOX_CLICKED = 'Eligibility Checkbox Clicked',
  EMR_RELEASE_ADDRESS_MANUAL_INPUT_CLICKED = 'EMR Release Address Manual Input Clicked',
  EMR_RELEASE_INPUT_MODIFIED = 'EMR Release Input Modified',
  EMR_RELEASE_VIDEO_OPENED = 'EMR Release Video Opened',
  EMR_RELEASE_DETAILS_SUBMIT_CLICKED = 'EMR Release Details Submit Clicked',
  EMR_RELEASE_SECTION_SKIPPED = 'EMR Release Section Skipped',
  EMR_RELEASE_SIGNATURE_SUBMITTED = 'EMR Release Signature Submitted',
  EMR_RELEASE_SKIP_CLICKED = 'EMR Release Skip Clicked',
  EXPAND_TRIAL_SUMMARY_CLICKED = 'Expand Trial Summary Clicked',
  FAQ_CONJURED_FAQ_CLICKED = 'FAQ Conjured FAQ Clicked',
  FAQ_UPVOTE_CLICKED = 'FAQ Upvote Clicked',
  GET_NOTIFIED_ABOUT_TRIALS_SUCCESS = 'Get Notified About Trials Success', // Valueable Event - FB: Subscribe
  HOSPITAL_FAQ_ACCORDION_CLICKED = 'Hospital FAQ Accordion Clicked',
  INBOX_BLOCK_PATIENT_MODAL_CLOSED = 'Inbox Block Patient Modal Closed',
  INBOX_BLOCK_PATIENT_MODAL_OPENED = 'Inbox Block Patient Modal Opened',
  INBOX_BLOCK_PATIENT_CONFIRM_BUTTON_CLICKED = 'Inbox Block Patient Confirm Button Clicked',
  INBOX_CHANNEL_CREATED = 'Inbox Channel Created',
  INBOX_CHANNEL_CREATION_FAILED = 'Inbox Channel Creation Failed',
  INBOX_CHANNEL_SELECTED = 'Inbox Channel Selected',
  INBOX_DETAILS_PANEL_CLOSED = 'Inbox Details Panel Closed',
  INBOX_DETAILS_PANEL_OPENED = 'Inbox Details Panel Opened',
  INBOX_DETAILS_SITE_PHONE_CLICKED = 'Inbox Details Site Phone Clicked',
  INBOX_DETAILS_TRIAL_INFO_CLICKED = 'Inbox Details Trial Info Clicked',
  INBOX_DETAILS_VIEW_APPLY_CLICKED = 'Inbox Details View Apply Clicked',
  INBOX_DETAILS_VIEW_SITE_CLICKED = 'Inbox Details View Site Clicked',
  INBOX_MESSAGE_SENT = 'Inbox Message Sent',
  INBOX_PAT_NAV_AGENT_PAUSE_CLICKED = 'Inbox Pat Nav Agent Pause Button Clicked',
  INBOX_PAT_NAV_AGENT_PLAY_CLICKED = 'Inbox Pat Nav Agent Play Button Clicked',
  INBOX_PAT_NAV_AGENT_TRANSCRIPTION_COPY_TO_CLIPBOARD_CLICKED = 'Inbox Pat Nav Agent Transcription Modal Copy To Clipboard Clicked',
  INBOX_PAT_NAV_AGENT_TRANSCRIPTION_MODAL_CLOSED = 'Inbox Pat Nav Agent Transcription Modal Closed',
  INBOX_PAT_NAV_AGENT_TRANSCRIPTION_MODAL_OPENED = 'Inbox Pat Nav Agent Transcription Modal Opened',
  INBOX_PROMPT_SELECTED = 'Inbox Prompt Selected',
  INBOX_SCREENING_APPOINTMENT_SCHEDULE_CLICKED = 'Inbox Screening Appointment Schedule Clicked',
  INBOX_SCREENING_APPOINTMENT_BOOKED = 'Inbox Screening Appointment Booked',
  INBOX_SCREENING_CALENDAR_DOWNLOAD_CLICKED = 'Inbox Screening Calendar Download Clicked',
  INBOX_SECONDARY_MENU_CLOSED = 'Inbox Secondary Menu Closed',
  INBOX_SECONDARY_MENU_OPENED = 'Inbox Secondary Menu Opened',
  INTERLINKED_GUIDE_ARTICLE_CLICKED = 'Interlinked Guide Article Clicked',
  INTERLINKED_TRIAL_CLICKED = 'Interlinked Trials Clicked',
  LOCATION_MAP_DOCTOR_CONTACT_NOW_CLICKED = 'Location Map Doctor Contact Now Clicked',
  LOCATION_MAP_DOCTOR_LEARN_MORE_CLICKED = 'Location Map Doctor Learn More Clicked',
  MAP_SEARCH_RELATED_TREATMENT_CLICKED = 'Map Search Related Treatment Clicked',
  MAP_SEARCH_TRIAL_CARD_CTA_CLICKED = 'Map Search Trial Card CTA Clicked',
  MAP_SEARCH_TRIAL_MAP_CLICKED = 'Map Search Trial Map Clicked',
  MAP_SEARCH_TRIAL_MAP_CARD_CTA_CLICKED = 'Map Search Trial Map Card CTA Clicked',
  MAP_SEARCH_TRIAL_MATCH_CLICKED = 'Map Search Trial Match Clicked',
  MAP_SEARCH_TRIAL_NAVIGATION_LEFT_CLICKED = 'Map Search Trial Navigation Left Clicked',
  MAP_SEARCH_TRIAL_NAVIGATION_RIGHT_CLICKED = 'Map Search Trial Navigation Right Clicked',
  MAP_SEARCH_TRIAL_PAGINATION_CLICKED = 'Map Search Trial Pagination Clicked',
  MAP_SEARCH_FAQ_EXPAND_CLICKED = 'Map Search FAQ Expand Clicked',
  MAP_SEARCH_FAQ_COLLAPSE_CLICKED = 'Map Search FAQ Collapse Clicked',
  MAP_SEARCH_FILTER_SELECTBOX_CHANGED = 'Map Search Filter Selectbox Changed',
  MAP_SEARCH_FILTER_TOGGLE_CLICKED = 'Map Search Filter Toggle Clicked',
  MAP_SEARCH_CLEAR_FILTERS_CLICKED = 'Map Search Clear Filters Clicked',
  MAP_SEARCH_VIEW_RESULTS_CLICKED = 'Map Search View Results Clicked',
  PAST_APPLICATIONS_CTA_CLICKED = 'Past Applications CTA Clicked',
  PATIENT_BURGER_MENU_ITEM_CLICKED = 'Patient Burger Menu Item Clicked',
  PATIENT_NAVIGATOR_AGENT_CALL_ENDED = 'Patient Navigator Agent Call Ended',
  PATIENT_NAVIGATOR_AGENT_CALL_END_CLICKED = 'Patient Navigator Agent Call End Clicked',
  PATIENT_NAVIGATOR_AGENT_CALL_SKIPPED = 'Patient Navigator Agent Call Skipped',
  PATIENT_NAVIGATOR_AGENT_CALL_ERRORED = 'Patient Navigator Agent Call Errored',
  PATIENT_NAVIGATOR_AGENT_CALL_STARTED = 'Patient Navigator Agent Call Started',
  PATIENT_NAVIGATOR_AGENT_MICROPHONE_DENIED = 'Patient Navigator Agent Microphone Denied',
  PATIENT_NAVIGATOR_AGENT_MICROPHONE_GRANTED = 'Patient Navigator Agent Microphone Granted',
  PATIENT_NAVIGATOR_AGENT_MICROPHONE_REQUESTED = 'Patient Navigator Agent Microphone Requested',
  PATIENT_NAVIGATOR_AGENT_PRIVACY_POLICY_CLICKED = 'Patient Navigator Agent Privacy Policy Clicked',
  PATIENT_NAVIGATOR_AGENT_OPEN_CALL_SEEN = 'Patient Navigator Agent Open Call Seen',
  PATIENT_NAVIGATOR_AGENT_SKIP_BUTTON_CLICKED = 'Patient Navigator Agent Skip Button Clicked',
  PATIENT_NAVIGATOR_AGENT_START_BUTTON_CLICKED = 'Patient Navigator Agent Start Button Clicked',
  PATIENT_REGISTRY_ENROLLMENT_CONSENT_SCROLLED = 'Patient Registry Enrollment Consent Scrolled',
  PATIENT_REGISTRY_ENROLLMENT_GUARDIAN_CLICKED = 'Patient Registry Enrollment Guardian Clicked',
  PATIENT_REGISTRY_ENROLLMENT_NO_CLICKED = 'Patient Registry Enrollment No Clicked',
  PATIENT_REGISTRY_ENROLLMENT_SELF_CLICKED = 'Patient Registry Enrollment Self Clicked',
  PATIENT_REGISTRY_ENROLLMENT_YES_CLICKED = 'Patient Registry Enrollment Yes Clicked',
  PATIENT_SIGNAL_OF_VALUE = 'Patient Signal Of Value',
  PDF_VIEWER_BACK_CLICKED = 'PDF Viewer Back Clicked',
  PDF_VIEWER_DOCUMENT_LOADED = 'PDF Viewer Document Loaded',
  PDF_VIEWER_DOWNLOAD_CLICKED = 'PDF Viewer Download Clicked',
  PDF_VIEWER_NEXT_PAGE_CLICKED = 'PDF Viewer Next Page Clicked',
  PDF_VIEWER_PAGE_LINK_CLICKED = 'PDF Viewer Page Link Clicked',
  PDF_VIEWER_PREVIOUS_PAGE_CLICKED = 'PDF Viewer Previous Page Clicked',
  PDF_VIEWER_ZOOM_IN_CLICKED = 'PDF Viewer Zoom In Clicked',
  PDF_VIEWER_ZOOM_OUT_CLICKED = 'PDF Viewer Zoom Out Clicked',
  PDF_VIEWER_ZOOM_SET = 'PDF Viewer Zoom Set',
  PHONE_RELAY_PIN_ENTERED = 'Phone Relay Pin Entered',
  POST_TRIAL_APPLY_MTA_SKIPPED = 'Post Trial Apply MTA Skipped',
  POST_TRIAL_APPLY_NEXT_BUTTON_CLICKED = 'Post Trial Apply Next Button Clicked',
  POST_TRIAL_APPLY_QUESTION_MODIFIED = 'Post Trial Apply Question Modified',
  POST_TRIAL_APPLY_SHARE_STORY_CONSENT_SUBMIT_BUTTON_CLICKED = 'Post Trial Apply Share Story Submit Button Clicked',
  POST_TRIAL_APPLY_STEP_VIEWED = 'Post Trial Apply Step Viewed',
  POST_TRIAL_APPLY_WHY_APPLY_SUBMIT_BUTTON_CLICKED = 'Post Trial Apply Why Apply Submit Button Clicked',
  PRODUCT_TOUR_STEP_COMPLETED = 'Product Tour Step Completed',
  PRODUCT_TOUR_COMPLETED = 'Product Tour Completed',
  PRODUCT_TOUR_DISMISSED = 'Product Tour Dismissed',
  PROVIDER_LANDER_CTA_CLICKED = 'Provider Lander CTA Clicked',
  REFERRAL_COPY_LINK_CLICKED = 'Referral Copy Link Clicked',
  REQUEST_SIGN_IN = 'Request Sign In',
  RESEARCHER_LANDER_CTA_CLICKED = 'Researcher Lander CTA Clicked',
  SCREENING_VISIT_SSP_OPTION_CLICKED = 'Screening Visit SSP Option Clicked',
  SCREENING_VISIT_SSP_ACCORDION_CLICKED = 'Screening Visit SSP Accordion Clicked',
  SCREENING_VISIT_SSP_SELECT_CHANGED = 'Screening Visit SSP Select Changed',
  SCREENING_VISIT_BOOKING_GMAIL_CLICKED = 'Screening Visit Booking Gmail Clicked',
  SCREENING_VISIT_BOOKING_OUTLOOK_CLICKED = 'Screening Visit Booking Outlook Clicked',
  SEARCH_EMPTY_STATE_VIEWED = 'Search Empty State Viewed', // Valueable Event - FB: Search
  SEARCH_PAGE_NUMBER_CLICKED = 'Search Page Number Clicked', // Valueable Event - FB: Search
  SEARCH_PAGINATION_NEXT_CLICKED = 'Search Pagination Next Clicked',
  SEARCH_PAGINATION_PREVIOUS_CLICKED = 'Search Pagination Previous Clicked',
  SHARE_TRIAL_MODAL_CLOSED = 'Share Trial Modal Closed',
  SHARE_TRIAL_MODAL_OPENED = 'Share Trial Modal Opened', // Valueable Event - FB: Donate
  SHARE_TRIAL_VIA_COPY_LINK_CLICKED = 'Share Trial Via Copy Link Clicked',
  SHARE_TRIAL_VIA_EMAIL_CLICKED = 'Share Trial Via Email Clicked',
  SHARE_TRIAL_VIA_FACEBOOK_SHARE_CLICKED = 'Share Trial Via Facebook Share Clicked',
  SHARE_TRIAL_VIA_FACEBOOK_MESSENGER_CLICKED = 'Share Trial Via Facebook Messenger Clicked',
  SHARE_TRIAL_VIA_LINKED_IN_CLICKED = 'Share Trial Via LinkedIn Share Clicked',
  SHARE_TRIAL_VIA_REDDIT_CLICKED = 'Share Trial Via Reddit Share Clicked',
  SHARE_TRIAL_VIA_TWITTER_CLICKED = 'Share Trial Via Twitter Share Clicked',
  SHOW_ORIGINAL_ELIGIBILITY_CRITERIA_CLICKED = 'Show Original Eligibility Criteria Clicked',
  SHOW_SUMMARY_ELIGIBILITY_CRITERIA_CLICKED = 'Show Summary Eligibility Criteria Clicked',
  SIDE_EFFECTS_ARM_GROUP_CHANGED = 'Side Effects Arm Group Changed',
  SIGN_OUT_BUTTON_CLICKED = 'Sign Out Button Clicked',
  SIGNED_IN_PATIENT_WEBSITE_SITE_STAFF_PORTAL_BUTTON_CLICKED = 'Signed In Patient Website Site Staff Portal Button Clicked',
  SIGNED_IN_PATIENT_WEBSITE_BECOME_TRIAL_PRO_BUTTON_CLICKED = 'Signed In Patient Website Become Trial Pro Button Clicked',
  SPONSORS_LANDER_CONDITION_SELECTED = 'Sponsors Lander Condition Selected',
  SPONSORS_LANDER_CTA_CLICKED = 'Sponsors Lander CTA Clicked',
  SPONSORS_LANDER_RECRUITMENT_DEADLINE_SELECTED = 'Sponsors Lander Recruitment Deadline Selected',
  SPONSORS_LANDER_TRIAL_SELECTED = 'Sponsors Lander Trial Selected',
  SUMMARIZE_TRIAL_DESCRIPTION_CLICKED = 'Summarize Trial Description Clicked', // Valueable Event - FB: ViewContent
  TREATMENT_TOPIC_CATEGORY_CARD_CLICKED = 'Treatment Topic Category Card Clicked',
  PATIENT_CLIENT_ERROR_BOUNDARY_HIT = 'Patient Client Error Boundary',
  TRIAL_APPLICATION_QUESTION_MODIFIED = 'Trial Application Question Modified',
  TRIAL_APPLICATION_QUESTION_VIEWED = 'Trial Application Question Viewed',
  TRIAL_APPLICATION_SEARCH_ITEM_SELECTED = 'Trial Application Search Item Selected',
  TRIAL_APPLICATION_SEARCH_ITEM_REMOVED = 'Trial Application Search Item Removed',
  TRIAL_APPLICATION_SEARCH_POPULAR_ITEM_SELECTED = 'Trial Application Search Popular Item Selected',
  TRIAL_APPLICATION_START_BUTTON_CLICKED = 'Trial Application Start Button Clicked',
  TRIAL_APPLICATION_SUBMITTED = 'Trial Application Submitted',
  TRIAL_APPLICATION_LOCATION_BADGES_VIEWED = 'Trial Application Location Badges Viewed',
  TRIAL_APPLICATION_LOCATION_MULTI_SITE_VIEWED = 'Trial Application Location Multi Site Viewed',
  TRIAL_APPLICATION_CONDITION_MATCHED_SUBMITTED = 'Trial Application Condition Matched Submitted', // Valuable Event - can be mapped to Payment Event
  TRIAL_APPLICATION_CONDITION_CHECKBOX_UI_VIEWED = 'Trial Application Condition Checkbox UI Viewed',
  TRIAL_APPLICATION_CONDITION_PILL_UI_VIEWED = 'Trial Application Condition Pill UI Viewed',
  TRIAL_APPLICATION_QUALIFIED_SUBMITTED = 'Trial Application Qualified Submitted', // Valueable Event - can be mapped to Payment Event
  TRIAL_LISTING_CLICKED = 'Trial Listing Clicked', // Valueable Event - FB: ViewContent
  TRIAL_PRO_AGE_RANGE_FILTER_CHANGED = 'Trial Pro Age Range Filter Changed',
  TRIAL_PRO_BOOK_ONBOARDING_CALL_CLICKED = 'Trial Pro Book Onboarding Call Clicked',
  TRIAL_PRO_CONDITIONS_FILTER_CHANGED = 'Trial Pro Conditions Filter Changed',
  TRIAL_PRO_DISTANCE_FILTER_CHANGED = 'Trial Pro Distance Filter Changed',
  TRIAL_PRO_DOSSIER_ACCORDION_CLOSED = 'Trial Pro Dossier Accordion Closed',
  TRIAL_PRO_DOSSIER_ACCORDION_OPENED = 'Trial Pro Dossier Accordion Opened',
  TRIAL_PRO_DOSSIER_AUTH_ERROR = 'Trial Pro Dossier Auth Error',
  TRIAL_PRO_DOSSIER_AUTH_SENT_LOGIN_LINK = 'Trial Pro Dossier Auth Sent Login Link',
  TRIAL_PRO_DOSSIER_AUTH_SUCCESSFUL = 'Trial Pro Dossier Auth Successful',
  TRIAL_PRO_DOSSIER_AUTH_USER_CREATE_ERROR = 'Trial Pro Dossier Auth User Create Error',
  TRIAL_PRO_DOSSIER_ELIGIBILITY_RESPONSE_UPDATED = 'Trial Pro Dossier Eligibility Response Updated',
  TRIAL_PRO_DOSSIER_ELIGIBILITY_VERIFICATION_METHOD_UPDATED = 'Trial Pro Dossier Eligibility Verification Method Updated',
  TRIAL_PRO_DOSSIER_NOTE_CREATED = 'Trial Pro Dossier Note Created',
  TRIAL_PRO_DOSSIER_SCHEDULING_MODAL_OPENED = 'Trial Pro Dossier Scheduling Modal Opened',
  TRIAL_PRO_DOSSIER_SCHEDULING_MODAL_SUBMITTED = 'Trial Pro Dossier Scheduling Modal Submitted',
  TRIAL_PRO_DOSSIER_TAB_CHANGED = 'Trial Pro Dossier Tab Changed',
  TRIAL_PRO_DOSSIER_TASK_ASSIGNED = 'Trial Pro Dossier Task Assigned',
  TRIAL_PRO_DOSSIER_TASK_COMPLETED = 'Trial Pro Dossier Task Completed',
  TRIAL_PRO_DOSSIER_TASK_UNCOMPLETED = 'Trial Pro Dossier Task Uncompleted',
  TRIAL_PRO_DOSSIER_VIEW = 'Trial Pro Dossier View',
  TRIAL_PRO_ELIGIBILITY_FILTER_CHANGED = 'Trial Pro Eligibility Filter Changed',
  TRIAL_PRO_GET_STARTED_BOOKING_FALLBACK = 'Trial Pro Get Started Booking Fallback',
  TRIAL_PRO_GET_STARTED_NEXT_STEP = 'Trial Pro Get Started Next Step',
  TRIAL_PRO_GET_STARTED_PREV_STEP = 'Trial Pro Get Started Prev Step',
  TRIAL_PRO_GET_STARTED_SITE_CREATED = 'Trial Pro Get Started Site Created',
  TRIAL_PRO_GET_STARTED_SITE_SELECTED = 'Trial Pro Get Started Site Selected',
  TRIAL_PRO_GET_STARTED_SITE_UNCREATED = 'Trial Pro Get Started Site Un-Created',
  TRIAL_PRO_GET_STARTED_SITE_UNSELECTED = 'Trial Pro Get Started Site Un-Selected',
  TRIAL_PRO_GET_STARTED_TRIALS_AND_SITES_CLAIMED = 'Trial Pro Get Started Trals And Sites Claimed',
  TRIAL_PRO_GET_STARTED_TRIAL_SELECTED = 'Trial Pro Get Started Trial Selected',
  TRIAL_PRO_GET_STARTED_TRIAL_UNSELECTED = 'Trial Pro Get Started Trial Un-Selected',
  TRIAL_PRO_GET_STARTED_USER_CREATED = 'Trial Pro Get Started User Created',
  TRIAL_PRO_IN_PRODUCT_EMR_UPSELL_CLICKED = 'Trial Pro In Product EMR Upsell Clicked',
  TRIAL_PRO_MESSAGES_BUTTON_CLICKED = 'Trial Pro Message Button Clicked',
  TRIAL_PRO_NAV_BAR_SELECT_SITE_CLICKED = 'Trial Pro Nav Bar Select Site Clicked',
  TRIAL_PRO_NAV_BAR_SELECT_TRIAL_CLICKED = 'Trial Pro Nav Bar Select Trial Clicked',
  TRIAL_PRO_PATIENT_DOCUMENT_CLICKED = 'Trial Pro Patient Document Clicked',
  TRIAL_PRO_PATIENT_PROFILE_ACTIONABLE_MATCH_CLICKED = 'Trial Pro Patient Profile Actionable Match Clicked',
  TRIAL_PRO_PATIENT_PROFILE_UNCONTACTED_PATIENTS_OPENED = 'Trial Pro Patient Profile Uncontacted Patients Opened',
  TRIAL_PRO_PATIENT_PROFILE_UNREAD_PATIENTS_OPENED = 'Trial Pro Patient Profile Unread Patients Opened',
  TRIAL_PRO_PATIENT_PROFILE_PRE_SCREENER_PAGE_NUMBER_CLICKED = 'Trial Pro Patient Profile Pre Screener Page Number Clicked',
  TRIAL_PRO_PATIENT_PROFILE_NEXT_PATIENT_BUTTON_CLICKED = 'Trial Pro Patient Profile Next Patient Button Clicked',
  TRIAL_PRO_PATIENT_PROFILE_REQUEST_EMR_FAILED = 'Trial Pro Patient Profile Request EMR Failed',
  TRIAL_PRO_PATIENT_PROFILE_REQUEST_EMR_SUCCEEDED = 'Trial Pro Patient Profile Request EMR Succeeded',
  TRIAL_PRO_PATIENT_PROFILE_TAB_CHANGED = 'Trial Pro Patient Profile Tab Changed',
  TRIAL_PRO_PRE_SCREENER_ADD_EXCLUSION_BUTTON_CLICKED = 'Trial Pro Pre Screener Add Exclusion Button Clicked',
  TRIAL_PRO_PRE_SCREENER_ADD_INCLUSION_BUTTON_CLICKED = 'Trial Pro Pre Screener Add Inclusion Button Clicked',
  TRIAL_PRO_ONBOARDING_BACK_BUTTON_CLICKED = 'Trial Pro Onboarding Back Navigation Clicked',
  TRIAL_PRO_ONBOARDING_CONDITION_SEARCH_UPDATED = 'Trial Pro Onboarding Condition Search Updated',
  TRIAL_PRO_ONBOARDING_FORM_SUBMITTED_VIA_CLIENT = 'Trial Pro Onboarding Form Submitted Via Client',
  TRIAL_PRO_ONBOARDING_NEXT_BUTTON_CLICKED = 'Trial Pro Onboarding Next Button Clicked',
  TRIAL_PRO_ONBOARDING_QUESTION_MODIFIED = 'Trial Pro Onboarding Question Modified',
  TRIAL_PRO_ONBOARDING_QUESTION_VIEWED = 'Trial Pro Onboarding Question Viewed',
  TRIAL_PRO_ONBOARDING_REGISTRY_BUTTON_CLICKED = 'Trial Pro Onboarding Registry Button Clicked',
  TRIAL_PRO_ONBOARDING_SKIP_BUTTON_CLICKED = 'Trial Pro Onboarding Skip Button Clicked',
  TRIAL_PRO_ONBOARDING_SUBMIT_BUTTON_CLICKED = 'Trial Pro Onboarding Submit Button Clicked',
  TRIAL_PRO_ONBOARDING_TRIAL_SEARCH_UPDATED = 'Trial Pro Onboarding Trial Search Updated',
  TRIAL_PRO_REFERRALS_APPLICATION_DISQUALIFIED_REASON_SELECTED = 'Trial Pro Referrals Application Disqualification Reason Selected',
  TRIAL_PRO_REFERRALS_APPLICATION_FOLLOWUP_DATE_SELECTED = 'Trial Pro Referrals Application Follow Up Date Selected',
  TRIAL_PRO_REFERRALS_APPLICATION_HAD_SCREENING_VISIT_SELECTED = 'Trial Pro Referrals Application Had Screening Visit Selected',
  TRIAL_PRO_REFERRALS_APPLICATION_OWNER_UPDATED = 'Trial Pro Referrals Application Owner Updated',
  TRIAL_PRO_REFERRALS_APPLICATION_STATUS_MODAL_OPENED = 'Trial Pro Referrals Application Status Modal Opened',
  TRIAL_PRO_REFERRALS_APPLICATION_STATUS_UPDATED = 'Trial Pro Referrals Application Status Updated',
  TRIAL_PRO_REFERRALS_APPLICATION_WILL_NOT_ENROLL_TYPE_SELECTED = 'Trial Pro Referrals Application Will Not Enroll Type Selected',
  TRIAL_PRO_REFERRALS_APPLY_NOTE_CREATED = 'Trial Pro Referrals Apply Note Created',
  TRIAL_PRO_REFERRALS_DISTANCE_QUICK_FILTER_OPENED = 'Trial Pro Referrals Distance Quick Filter Opened',
  TRIAL_PRO_REFERRALS_DOCTOR_DETAILS_UPDATED = 'Trial Pro Referrals Doctor Details Updated',
  TRIAL_PRO_REFERRALS_EMR_CONSENT_DOWNLOADED = 'Trial Pro Referrals EMR Consent Downloaded',
  TRIAL_PRO_REFERRALS_EMR_CONSENT_LINK_COPIED = 'Trial Pro Referrals EMR Consent Link Copied',
  TRIAL_PRO_REFERRALS_FILTER_ALL_TOGGLED = 'Trial Pro Referrals Filter All Toggled',
  TRIAL_PRO_REFERRALS_FILTER_CHANGED = 'Trial Pro Referrals Filter Changed',
  TRIAL_PRO_REFERRALS_FILTER_MENU_OPENED = 'Trial Pro Referrals Filter Menu Opened',
  TRIAL_PRO_REFERRALS_MEDICAL_RECORD_FILE_UPLOADED = 'Trial Pro Referrals Medical Record File Uploaded',
  TRIAL_PRO_REFERRALS_PATIENT_EMAIL_COPIED = 'Trial Pro Referrals Patient Email Copied',
  TRIAL_PRO_REFERRALS_PATIENT_DOCUMENTS_SECTION_VIEWED = 'Trial Pro Referrals Patient Documents Section Viewed',
  TRIAL_PRO_REFERRALS_PATIENT_IDENTIFIER_COPIED = 'Trial Pro Referrals Patient Identifier Copied',
  TRIAL_PRO_REFERRALS_PATIENT_PHONE_COPIED = 'Trial Pro Referrals Patient Phone Copied',
  TRIAL_PRO_REFERRALS_PATIENT_PROFILE_APPLICATION_DROPDOWN = 'Trial Pro Referrals Application Dropdown Clicked',
  TRIAL_PRO_REFERRALS_PATIENT_SCREENING_VISIT_BOOK_CLICKED = 'Trial Pro Referrals Patient Screening Visit Book Clicked',
  TRIAL_PRO_REFERRALS_PATIENT_SCREENING_VISIT_BOOK_VIEWED = 'Trial Pro Referrals Patient Screening Visit Book Viewed',
  TRIAL_PRO_REFERRALS_SCHEDULE_SCREENING_AVAILABILITY_BUTTON_VIEWED = 'Trial Pro Referrals Schedule Screening Availability Button Viewed',
  TRIAL_PRO_REFERRALS_SELECTED_CLINICAL_LOCATION_FILTER_CHANGED = 'Trial Pro Referrals Selected Clinical Location Filter Changed',
  TRIAL_PRO_REFERRALS_SELECTED_SITE_FILTER_CHANGED = 'Trial Pro Referrals Selected Site Filter Changed',
  TRIAL_PRO_REFERRALS_SELECTED_TRIAL_FILTER_CHANGED = 'Trial Pro Referrals Selected Trial Filter Changed',
  TRIAL_PRO_REFERRALS_SITE_QUICK_FILTER_OPENED = 'Trial Pro Referrals Site Quick Filter Opened',
  TRIAL_PRO_REFERRALS_SORT_MENU_OPENED = 'Trial Pro Referrals Sort Menu Opened',
  TRIAL_PRO_REFERRALS_SORTING_CHANGED = 'Trial Pro Referrals Sorting Changed',
  TRIAL_PRO_REFERRALS_STAGE_QUICK_FILTER_OPENED = 'Trial Pro Referrals Stage Quick Filter Opened',
  TRIAL_PRO_REFERRALS_TRIAL_QUICK_FILTER_OPENED = 'Trial Pro Referrals Trial Quick Filter Opened',
  TRIAL_PRO_REFERRALS_TRIAL_REASSIGNED = 'Trial Pro Referrals Trial Reassigned',
  TRIAL_PRO_REFERRALS_CONTACTED_PATIENT_CALLOUT_CLICKED = 'Trial Pro Referrals Contacted Patient Callout Clicked',
  TRIAL_PRO_REFERRALS_CONTACTED_PATIENT_CALLOUT_CLOSED = 'Trial Pro Referrals Contacted Patient Callout Closed',
  TRIAL_PRO_REFERRALS_CONTACTED_PATIENT_CALLOUT_SHOWN = 'Trial Pro Referrals Contacted Patient Callout Shown',
  TRIAL_PRO_REGISTRY_CLEAR_FILTERS_BUTTON_CLICKED = 'Trial Pro Registry Clear Filters Button Clicked',
  TRIAL_PRO_REGISTRY_FILTER_APPLIED = 'Trial Pro Registry Filter Applied',
  TRIAL_PRO_REGISTRY_INVITATION_EMAIL_SENT = 'Trial Pro Registry Invitation Email Sent',
  TRIAL_PRO_REGISTRY_INVITATION_SMS_SENT = 'Trial Pro Registry Invitation SMS Sent',
  TRIAL_PRO_REGISTRY_INVITE_BUTTON_CLICKED_MODAL_OPENED = 'Trial Pro Registry Invite Button Clicked',
  TRIAL_PRO_REGISTRY_INVITE_NO_CREDITS_RETURNED = 'Trial Pro Registry Invite Patient Modal No Credits Returned',
  TRIAL_PRO_REGISTRY_INVITE_SUBMITTED = 'Trial Pro Registry Invite Patient Modal Submitted',
  TRIAL_PRO_REGISTRY_PAGE_LOADED_EMPTY_STATE = 'Trial Pro Registry Page Loaded Empty State',
  TRIAL_PRO_REGISTRY_PAGE_LOADED_WITH_RESULTS = 'Trial Pro Registry Page Loaded With Results',
  TRIAL_PRO_REGISTRY_PASS_MODAL_OPENED = 'Trial Pro Registry Pass Patient Modal Opened',
  TRIAL_PRO_REGISTRY_PASS_MODAL_SUBMITTED = 'Trial Pro Registry Pass Patient Modal Submitted',
  TRIAL_PRO_REGISTRY_PATIENT_CARD_SHARED = 'Trial Pro Registry Patient Card Shared',
  TRIAL_PRO_REGISTRY_PATIENT_DETAILS_MODAL_OPENED = 'Trial Pro Registry Patient Details Modal Opened',
  TRIAL_PRO_REGISTRY_REQUEST_INVITATIONS_MODAL_SUBMITTED = 'Trial Pro Registry Request Invitations Modal Submitted',
  TRIAL_PRO_REGISTRY_SEARCH_EXECUTED = 'Trial Pro Registry Search Executed',
  TRIAL_PRO_REGISTRY_SELECTED_SITE_FILTER_CHANGED = 'Trial Pro Registry Selected Site Filter Changed',
  TRIAL_PRO_REGISTRY_SELECTED_TRIAL_FILTER_CHANGED = 'Trial Pro Registry Selected Trial Filter Changed',
  TRIAL_PRO_REWARDS_PAGE_LINK_CLICKED = 'Trial Pro Rewards Page Link Clicked',
  TRIAL_PRO_SEX_AT_BIRTH_FILTER_CHANGED = 'Trial Pro Sex At Birth Filter Changed',
  TRIAL_PRO_SITE_ADDED = 'Trial Pro Site Added',
  TRIAL_PRO_SSP_ERROR_BOUNDARY_HIT = 'Trial Pro SSP Error Boundary Hit',
  TRIAL_PRO_TRIAL_SETTINGS_QUESTION_SUBMITTED = 'Trial Pro Trial Settings Question Submitted',
  VIDEO_CALL_CLIENT_INITIATED = 'Video Call Client Initiated',
  VIDEO_CALL_OPEN_BUTTON_CLICKED = 'Video Call Open Button Clicked',
  VIDEO_CALL_PATIENT_ENTERED_WAITING_ROOM = 'Video Call Patient Entered Waiting Room',
  VIDEO_CALL_PATIENT_JOINED = 'Video Call Patient Joined',
  VIDEO_CALL_BUTTON_SHOWN = 'Video Call Button Shown',
  VIDEO_CALL_PATIENT_LEFT = 'Video Call Patient Left',
  VIDEO_PLAYED = 'Video Played',
  VIEW_OTHER_TRIALS_CLICKED = 'View Other Trials Clicked',
  VIEW_TRIALS_CLICKED = 'View Trials Clicked',
}
