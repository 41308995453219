/**
 * Tracking source is used to differentiate between different instances of the same component.
 * For example, we have an "apply to trial" button in the table of contents and in the hero section of the trial page.
 */

export enum TrackingSources {
  APPLY_FLOW_V2 = 'apply_flow_v2',
  ARTICLE = 'article',
  CENTRAL_SITE = 'central_site',
  CRC_COPILOT = 'crc_copilot',
  CRC_COPILOT_ALL_STEPS = 'crc_copilot_all_steps',
  DIRECTORY = 'directory',
  DOCTOR_DIRECTORY = 'doctor_directory',
  ELIGIBILITY_TACHOMETER_CARD = 'eligibility_tachometer_card',
  FAQ = 'faq',
  FLOATING_MOBILE_CTA = 'floating_mobile_cta',
  GET_NOTIFIED_ABOUT_TRIALS_CARD = 'get_notified_about_trials_card',
  GET_STARTED_FLOW = 'get_started_flow',
  HOMEPAGE = 'homepage',
  INBOX = 'inbox',
  INSURANCE_DETAILS_CAPTURE_BUTTON_GROUP = 'insurance_details_capture_button_group',
  INSURANCE_DETAILS_CAPTURE_DETAILS = 'insurance_details_capture_details',
  INTERLINKING_LIST = 'interlinking_list',
  LISTING = 'listing',
  LISTING_HERO = 'listing_hero',
  LISTING_TABLE_OF_CONTENTS = 'listing_table_of_contents',
  LOWER_PHOTO_CARD = 'lower_photo_card',
  MATCH_PROFILE_COPILOT = 'match_profile_copilot',
  NAVBAR = 'navbar',
  PAID_LANDER_FOOTER = 'paid_lander_footer',
  PAID_LANDER_FEATURE = 'paid_lander_feature',
  PAID_LANDER_HERO = 'paid_lander_hero',
  PARTNER_TRIAL_SEARCH = 'partner-trial-search',
  PARTNER_TRIALS = 'partner_trials',
  PATIENT = 'patient',
  PATIENT_APPLY_PACKAGE = 'patient_apply_package',
  PATIENT_INBOX = 'patient_inbox',
  PATIENT_NAVIGATOR_TRANSCRIPTION_MODAL = 'patient_navigator_transcription_modal',
  PATIENT_POST_APPLY = 'patient_post_apply',
  PRIMARY_CTA = 'primary_cta',
  PROFILE = 'profile',
  PROVIDER_LANDER_FEATURE = 'provider_lander_feature',
  PROVIDER_LANDER_FOOTER = 'provider_lander_footer',
  PROVIDER_LANDER_HERO = 'provider_lander_hero',
  REFERRALS_TABLE = 'referrals_table',
  REFERRAL_LANDER = 'referral_lander',
  REFERRALS_TRIAL_APPLICATION_MODAL = 'referrals_trial_application_modal',
  REGISTRY = 'registry',
  RELATED_LINKS_TRIAL = 'related_links_trial',
  RESEARCHER = 'researcher',
  RESEARCHER_LANDER_FEATURE = 'researcher_lander_feature',
  RESEARCHER_LANDER_FOOTER = 'researcher_lander_footer',
  RESEARCHER_LANDER_HERO = 'researcher_lander_hero',
  SEARCH = 'search',
  SEARCH_EXPERIMENT = 'search_experiment',
  SITE_OR_CRO = 'site_or_cro',
  SPONSORS_LANDER_FOOTER = 'sponsors_lander_footer',
  SPONSORS_LANDER_HERO = 'sponsors_lander_hero',
  SPONSORS_LANDER_TOP_TRIALS = 'sponsors_lander_top_trials',
  SSP = 'ssp',
  TREATMENT_PAGE = 'treatment_page',
  TRIAL_APPLICATION_FORM = 'trial_application_form',
  TRIAL_PRO_DOSSIER = 'trial_pro_dossier',
  TRIAL_PRO_MATCHES_TABLE = 'trial_pro_matches_table',
  TRIAL_PRO_ONBOARDING = 'trial_pro_onboarding',
  TRIAL_PRO_PATIENT_PROFILE = 'trial_pro_patient_profile',
  TRIAL_PRO_PRE_SCREENER = 'trial_pro_pre_screener',
  TRIAL_PRO_UPCOMING_CTMS_INTEGRATION = 'trial_pro_upcoming_ctms_integration',
  TRIAL_PRO_UPCOMING_REPORTING = 'trial_pro_upcoming_reporting',
  TRIAL_PRO_UPCOMING_SITE_PAGE = 'trial_pro_upcoming_site_page',
  TRIAL_PRO_UPCOMING_TRIAL_SEARCH_WIDGET = 'trial_pro_upcoming_trial_search_widget',
  TRIAL_PRO_SETTINGS = 'trial_pro_settings',
}
